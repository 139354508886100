import React, { useContext } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { List, Space } from "antd"
import styled from "styled-components"
import { MessageOutlined, LikeOutlined, StarOutlined } from "@ant-design/icons"
import { ContentContext } from "../utils/ContentContext"

const ListPosts = styled(List)`
  .ant-list-item {
    padding: ${({ posttype }) =>
      (posttype === "grid" && `16px 24px`) || `16px 0`};
    display: ${({ posttype }) =>
      (posttype === "grid" && `inline-flex`) || `flex`};
    flex-flow: ${({ posttype }) =>
      (posttype === "grid" && `wrap-reverse`) || `row`};
    width: ${({ posttype }) => (posttype === "grid" && `50%`) || `100%`};
    flex-direction: row-reverse;
  }
  .ant-list-item-extra {
    margin: ${({ posttype }) =>
      (posttype === "grid" && `0 0 20px 0`) || `0 40px 0 0`};

    .gatsby-image-wrapper {
      width: ${({ posttype }) =>
        (posttype === "grid" && `100%`) ||
        (posttype === "list" && `200px`) ||
        `270px`};
    }
  }
`

const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
)
const Posts = ({ posts }) => {
  const { state } = useContext(ContentContext)
  const { postType } = state

  return (
    <ListPosts
      posttype={postType}
      itemLayout="vertical"
      size="large"
      style={{ overflow: "hidden" }}
      pagination={{
        style: { textAlign: "center" },
        onChange: page => {
          console.log(page)
        },
        pageSize: 8,
      }}
      dataSource={posts}
      renderItem={({ node }) => (
        <List.Item
          key={node.frontmatter.title || node.fields.slug}
          actions={[
            <IconText
              icon={StarOutlined}
              text="156"
              key="list-vertical-star-o"
            />,
            <IconText
              icon={LikeOutlined}
              text="156"
              key="list-vertical-like-o"
            />,
            <IconText
              icon={MessageOutlined}
              text="2"
              key="list-vertical-message"
            />,
          ]}
          extra={
            <Img ClassName={"postIcon"} fixed={node.frontmatter.banner.childImageSharp.fixed}/>
          }
        >
         
          <List.Item.Meta
            title={
              <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                <h2>{node.frontmatter.title || node.fields.slug}</h2>
              </Link>
            }
            description={node.frontmatter.date}
          />
          {node.frontmatter.description || node.excerpt}
        </List.Item>
      )}
    />
  )
}

export default Posts
